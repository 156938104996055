<template>
  <pcc-container-properties v-if="state.isSelected" :properties="false">
    <template slot="contentBar">
      <v-spacer></v-spacer>
      <pcc-button-tooltip
        v-on:click="actions.unselectItem()"
        :icon="Icons.goBack"
        position="bottom"
        :tooltip="$t('commons.operation.goBack')"
        :disabled="state.loading"
      />
      <pcc-button-tooltip
        v-if="state.items.length < 2"
        v-on:click="setDefaultEvent(), actions.saveItem()"
        :icon="Icons.save"
        position="bottom"
        :tooltip="$t('commons.operation.save')"
        :loading="state.loading"
        color="primary"
        textColor="primary"
      />
    </template>
    <template slot="content">
      <v-card class="elevation-0 ma-0 pa-0" tile>
        <v-form ref="form" lazy-validation v-if="state.items.length < 2">
          <v-card-text>
            <span class="subtitle-2 font-weight-light">{{
              $t('model.AffiliatePixels.fields.type')
            }}</span>
            <v-select
              v-model="state.item.type"
              @input="updateAndValidate({ key: 'type', value: $event })"
              :items="selectedItemList"
              item-text="name"
              item-value="value"
              v-bind:label="$t('model.AffiliatePixels.fields.type')"
              :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
              :disabled="state.item.type === serverMethodPost"
              required
              solo-inverted
              flat
              dense
              small-chips
            >
              <template v-slot:item="data">
                <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                  {{ notificationType(data.item.name) }}
                </v-chip>
              </template>
              <template v-slot:selection="data">
                <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                  {{ notificationType(data.item.name) }}
                </v-chip>
              </template>
            </v-select>
            <span class="subtitle-2 font-weight-light">{{
              $t('model.AffiliatePixels.fields.url')
            }}</span>
            <v-text-field
              ref="url"
              v-model="state.item.url"
              @input="actions.updateItemAttribute({ key: 'url', value: $event })"
              solo-inverted
              dense
              flat
              :label="$t('model.AffiliatePixels.fields.url')"
              :required="true"
              :rules="[
                (v) => !!v || $t('commons.rules.errorRequired'),
                (v) => validateUrlOrImgTag(v) || $t('commons.rules.errorInvalidUrl'),
              ]"
            />
            <macros-glossary
              v-if="RegexTypes.regexUrl.test(state.item.url)"
              @addMacros="addMacros($event)"
            />
          </v-card-text>
        </v-form>
        <v-alert v-else type="warning" elevation="0" colored-border text>
          {{ $t('model.AffiliatePixels.limitReached') }}
        </v-alert>
      </v-card>
    </template>
    <template slot="propertiesBar"> </template>
    <template slot="properties"> </template>
  </pcc-container-properties>
  <pcc-container-properties :properties="filter" v-else>
    <template slot="contentBar">
      <v-spacer />
      <pcc-button-tooltip
        v-on:click="
          actions.newItem({
            status: 'PENDING',
            type: 'SERVER_TO_SERVER_METHOD_GET',
            retryPolicy: 'DEFAULT',
            retryPolicyConfig: {},
            _affiliate: application.state.user._id,
            events: 'All Events',
            isGlobal: true,
          })
        "
        :icon="Icons.add"
        position="bottom"
        :tooltip="$t('commons.operation.addNewItem')"
        color="primary"
        textColor="primary"
      />
    </template>
    <template slot="content">
      <v-card class="elevation-0" tile>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="state.items"
            :loading="state.itemsLoading"
            item-key="_id"
            hide-default-footer
          >
            <template v-slot:item._id="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip small label class="ma-1" v-on="on" @click="copyToClipboard(item._id)">
                    {{ item._id | truncate(20) }}
                  </v-chip>
                </template>
                <span>{{ item._id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip small label class="ma-1" :color="colorByStatus(item.status)" dark>
                {{ item.status | upperCase | capitalize }}
              </v-chip>
            </template>
            <template v-slot:item.url="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip small label class="ma-1" v-on="on" @click="copyToClipboard(item.url)">
                    {{ item.url | truncate(30) }}
                  </v-chip>
                </template>
                <span>{{ item.url }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <pcc-button-edit-delete
                :viewEdit="false"
                v-on:delete="actions.deleteItem({ value: item })"
              ></pcc-button-edit-delete>
            </template>
            <template v-slot:item.type="{ item }">
              <v-chip small label class="ma-1">
                {{ notificationType(item.type) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </pcc-container-properties>
</template>

<script>
import { inject, onBeforeMount, onMounted, reactive } from '@vue/composition-api';
import AffiliatePixels from '../../store/private/AffiliatePixels';
import PccButtonTooltip from '../Components/PccButtonTooltip';
import PccContainerProperties from '../Components/PccContainerProperties';
import PccButtonShowHide from '../Components/PccButtonShowHide';
import PccButtonEditDelete from '../Components/PccButtonEditDelete';
import MacrosGlossary from '../Components/MacrosGlossary';
import RegexTypes from '../../store/constants/RegexTypes';

export default {
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
    'pcc-container-properties': PccContainerProperties,
    'pcc-button-show-hide': PccButtonShowHide,
    'pcc-button-edit-delete': PccButtonEditDelete,
    'macros-glossary': MacrosGlossary,
  },
  setup(props, context) {
    const application = inject('Application');
    const typeThirdPartyTracking = [
      {
        name: 'IMAGE_PIXEL',
        value: 'IMAGE_PIXEL',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_GET',
        value: 'SERVER_TO_SERVER_METHOD_GET',
      },
    ];
    const alternativeTypeThirdPartyTracking = [
      {
        name: 'IMAGE_PIXEL',
        value: 'IMAGE_PIXEL',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_GET',
        value: 'SERVER_TO_SERVER_METHOD_GET',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_POST',
        value: 'SERVER_TO_SERVER_METHOD_POST',
      },
    ];
    const serverMethodPost = 'SERVER_TO_SERVER_METHOD_POST';
    const self = AffiliatePixels(context, {
      _affiliate: application.state.user._id,
      isGlobal: true,
    });
    onBeforeMount(async () => {
      await self.actions.loadSystemSettings();
    });
    onMounted(async () => {
      await self.actions.loadItems();
    });
    const headers = [
      { text: 'ID', value: '_id', align: 'start', width: 150 },
      { text: 'Status', value: 'status', align: 'start', width: 100, sortable: true },
      { text: 'Type', value: 'type', align: 'start', width: 180, sortable: true },
      { text: 'Url', value: 'url', align: 'start', width: 200, sortable: true },
      { text: 'Actions', value: 'actions', align: 'center', width: 100, sortable: false },
    ];
    function notificationType(original) {
      const conversionObject = {
        IMAGE_PIXEL: 'Image Pixel',
        SCRIPT_PIXEL: 'Script Pixel',
        SERVER_TO_SERVER_METHOD_GET: 'Postback URL',
        SERVER_TO_SERVER_METHOD_POST: 'Postback POST',
        SERVER_TO_SERVER_POST: 'Postback POST',
        SERVER_TO_SERVER_GET: 'Postback URL',
      };
      return conversionObject[original];
    }
    function validateUrlOrImgTag(url) {
      if (this.state.item.type === 'IMAGE_PIXEL') {
        return RegexTypes.regexUrlOrImg.test(url);
      }
      return RegexTypes.regexUrl.test(url);
    }
    return {
      headers,
      typeThirdPartyTracking,
      alternativeTypeThirdPartyTracking,
      serverMethodPost,
      ...self,
      ...reactive({ filter: false }),
      RegexTypes,
      application,
      notificationType,
      validateUrlOrImgTag,
    };
  },
  computed: {
    selectedItemList() {
      return this.state.item.type === this.serverMethodPost
        ? this.alternativeTypeThirdPartyTracking
        : this.typeThirdPartyTracking;
    },
  },
  methods: {
    addMacros(macros) {
      if (macros.length > 0 && RegexTypes.regexUrl.test(this.state.item.url)) {
        const urlWithMacros =
          this.state.item.url.substr(0, this.$refs.url.$refs.input.selectionStart) +
          macros +
          this.state.item.url.substr(this.$refs.url.$refs.input.selectionEnd);
        this.actions.updateItemAttribute({ key: 'url', value: urlWithMacros });
      }
    },
    setDefaultEvent() {
      if (!this.state.item.events) {
        this.state.item.events = ['All Events'];
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
    colorByStatus(status) {
      switch (status) {
        case 'ACTIVE':
          return 'green';
        case 'BLOCKED':
          return 'error';
        case 'PENDING':
          return 'primary';
        case 'DELETED':
          return 'grey';
        default:
          return 'secondary';
      }
    },
    updateAndValidate({ key, value }) {
      this.actions.updateItemAttribute({ key, value });
      this.$refs.form.validate();
    },
  },
};
</script>
