var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pt-0 pb-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('model.PostEventNotifications.type'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('model.PostEventNotifications.url'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('model.PostEventNotifications.action'))+" ")])])]),_c('tbody',_vm._l((_vm.selectedObject.conversionNotifications),function(conversionNotification,index){return _c('tr',{key:index},[(conversionNotification.isNew)?_c('td',{staticStyle:{"width":"200px"}},[_c('v-select',{staticClass:"mt-5 pt-0",attrs:{"items":_vm.notificationTypes,"value":conversionNotification.type,"solo-inverted":"","dense":"","flat":"","small-chips":"","label":_vm.$t('model.PostEventNotifications.type')},on:{"input":function($event){return _vm.updateAttributeOnArrayNested({
                    key: 'conversionNotifications',
                    index: index,
                    attributeKey: 'type',
                    attributeValue: $event,
                  })}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"label":"","input-value":data.selected,"color":"transparent"}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"label":"","input-value":data.selected,"color":"transparent"}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.text)+" ")])]}}],null,true)})],1):_c('td',{staticStyle:{"width":"200px","height":"80px"}},[_c('v-chip',{staticClass:"ma-1",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.notificationType(conversionNotification.type))+" ")])],1),(conversionNotification.isNew)?_c('td',[_c('v-text-field',{ref:("url" + index),refInFor:true,staticClass:"mt-5 pt-0",attrs:{"value":conversionNotification.url,"solo-inverted":"","dense":"","flat":"","label":_vm.$t('model.PostEventNotifications.url'),"required":true,"rules":[
                  function (v) { return !!v || _vm.$t('commons.rules.errorRequired'); },
                  function (v) { return _vm.validateUrlOrImgTag(v, conversionNotification.type) ||
                    _vm.$t('commons.rules.errorInvalidUrl'); } ]},on:{"input":function($event){return _vm.updateAttributeOnArrayNested({
                    key: 'conversionNotifications',
                    index: index,
                    attributeKey: 'url',
                    attributeValue: $event,
                  })}}}),(_vm.regexUrl.test(conversionNotification.url))?_c('macros-glossary',{on:{"addMacros":function($event){return _vm.addMacros(index, conversionNotification.url, $event)}}}):_vm._e()],1):_c('td',[_vm._v(_vm._s(conversionNotification.url))]),_c('td',{staticStyle:{"width":"100px","vertical-align":"top","padding-top":"25px"}},[_c('pcc-button-tooltip',{attrs:{"icon":_vm.Icons.delete,"position":"bottom","tooltip":_vm.$t('commons.operation.delete')},on:{"click":function($event){return _vm.deleteOnArrayNested({ key: 'conversionNotifications', index: index })}}})],1)])}),0)]},proxy:true}])})],1),(_vm.selectedObject.conversionNotifications.length < 3)?_c('v-card-actions',[_c('v-spacer'),_c('pcc-button-tooltip',{attrs:{"icon":_vm.Icons.add,"position":"top","tooltip":_vm.$t('commons.operation.add')},on:{"click":function($event){return _vm.addToArrayNested({
          key: 'conversionNotifications',
          value: { type: 'IMAGE_PIXEL', url: '', isNew: true },
        })}}}),_c('v-spacer')],1):_c('v-alert',{attrs:{"type":"warning","elevation":"0","colored-border":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t('model.AffiliatePixels.limitReached'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }