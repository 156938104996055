<template>
  <v-card :class="`${isDarkTheme ? 'transparent elevation-0' : ''} pa-4`">
    <v-card-text class="pa-6">
      <v-row>
        <v-col cols="12" :class="`${isDarkTheme ? 'white-text' : ''} text-center`">
          <h1 class="title">{{ $t('commons.titles.twoFaAuthentication') }}</h1></v-col
        >
        <v-col cols="12" :class="`${isDarkTheme ? 'white-text' : ''} text-center`">
          {{ $t('model.TwoFactorAuthentication.instruction') }}
        </v-col>
        <v-col cols="12" class="pb-0 text-center">
          <v-text-field
            v-bind:label="$t('model.TwoFactorAuthentication.tokenFieldLabel')"
            :value="token"
            @input="updateTokenValue($event)"
            :error-messages="errorMsg"
            :error="!!errorMsg"
            :dark="isDarkTheme"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right py-0 px-3">
          <v-btn
            class="btn-primary rounded-pill"
            :disabled="!tokenFieldValidLength"
            @click="emitToken"
            :color="isDarkTheme ? 'white' : 'primary'"
            block
          >
            <v-progress-circular
              v-if="isValidatingToken"
              indeterminate
              width="1"
              size="25"
              :color="isDarkTheme ? 'black' : 'white'"
            ></v-progress-circular>
            <span v-else>{{ $t('commons.operation.verify') }}</span></v-btn
          >
        </v-col>
        <v-col class="text-center pa-0 pt-4">
          <span class="cancel-btn ma-0 font-weight-light" @click="cancel">
            <VIcon x-smal class="cancel-btn font-weight-light">{{ Icons.close }}</VIcon
            >{{ $t('commons.operation.cancel') }}</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import Icons from '../../icons';

export default {
  name: 'two-fa-token-modal',
  props: {
    isValidatingToken: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String | null,
      default: null,
    },
    tokenSomethingWentWrongMsg: {
      type: String | null,
      default: null,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    // state
    const tokenMinLength = ref(6);
    const token = ref('');

    // computed properties
    const tokenFieldValidLength = computed(() => {
      return token.value?.length >= tokenMinLength.value;
    });

    // methods
    const updateTokenValue = (value) => {
      token.value = value;
    };
    const cancel = () => {
      emit('canceling');
      updateTokenValue('');
    };
    const emitToken = () => {
      emit('token', token.value);
    };

    return {
      updateTokenValue,
      cancel,
      emitToken,
      tokenMinLength,
      token,
      tokenFieldValidLength,
      Icons,
    };
  },
};
</script>

<style scoped lang="scss">
.title {
  line-height: 33px;
}
.cancel-btn {
  font-size: 0.65rem !important;
  cursor: pointer;
}
.white-text {
  color: rgba(255, 255, 255, 0.9);
}
</style>
